import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Avatar,
  Button,
  Drawer,
  Layout,
  Menu,
  notification,
  Typography,
} from 'antd';

import { ReactComponent as ExportOutlined } from 'assets/icons/ExportOutlined.svg';
import { ReactComponent as LogoIcon } from 'assets/icons/Logo.svg';
import { useAuthContext } from 'contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { matchPath, useHistory } from 'react-router';
import navigationConfig, {
  MenuItem,
  navigationMenus,
} from 'routes/navigationConfig';
import BaseService from 'services/api/BaseService';
import { Header } from 'antd/es/layout/layout';
import {
  BellOutlined,
  LoadingOutlined,
  MenuOutlined,
  PlusOutlined,
  UserOutlined,
} from '@ant-design/icons';
import clsx from 'clsx';
import { useChangeAvatar } from 'views/auth/hook/auth_hook';

const { Content, Sider } = Layout;

const AppLayout = (props: { children: React.ReactNode }) => {
  const { children } = props;
  const { auth, logout } = useAuthContext();
  const [menuOpen, setMenuOpen] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const pathname = history.location.pathname;

  const handleLogout = useCallback(() => {
    logout();
    history.push('/connexion');
  }, [history, logout]);

  const fileInput = useRef<HTMLInputElement>(null);
  const [fileInputKey, setFileInputKey] = useState(0);

  const { mutate: changeAvatar, isPending: loadingUpdateAvatar } =
    useChangeAvatar();

  const handleMenuClick = (e: MenuItem) => {
    if (typeof e?.key === 'string') history.push(e.key);
  };

  const visibilityChange = () => {
    if (document.visibilityState === 'visible' || !document.hidden) {
      BaseService.checkToken();
    }
  };

  const handleTokenExpired = useCallback(() => {
    handleLogout();
    notification.info({
      message: t('global:session_expired'),
    });
  }, [handleLogout, t]);

  useEffect(() => {
    window.addEventListener('onTokenExpired', handleTokenExpired);
    document.addEventListener('visibilitychange', visibilityChange);
    return () => {
      window.removeEventListener('onTokenExpired', handleTokenExpired);
      document.removeEventListener('visibilitychange', visibilityChange);
    };
  }, [handleTokenExpired]);

  const items = navigationConfig(handleMenuClick);

  const defaultSelectedKeys =
    navigationMenus.find((item) =>
      matchPath(pathname, {
        path: `${item.key}${item.key === '/' ? '' : '*'}`,
        exact: true,
      }),
    )?.key ?? '/';

  const onAvatarInputChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = e.target.files?.[0];
    if (!file) {
      return;
    }
    // reset input file
    setFileInputKey((prev) => prev + 1);
    changeAvatar(file);
  };

  const slider = (className: string) => (
    <Sider
      className={clsx(
        'slider-override flex md:m-4 lg:m-7 rounded-lg flex-col',
        className,
      )}
      width={240}
    >
      <div className="flex flex-col items-center gap-4 pt-6">
        <input
          key={fileInputKey}
          onChange={onAvatarInputChange}
          ref={fileInput}
          type="file"
          accept={'.jpg,.jpeg,.png'}
          name={'image'}
          className={'hidden'}
        />
        <Avatar
          onClick={() => fileInput.current?.click()}
          className={'bg-white cursor-pointer bg-opacity-25 w-16 h-16'}
          src={auth?.user.avatarUrl}
          icon={
            loadingUpdateAvatar ? (
              <LoadingOutlined color={'#fff'} spin />
            ) : (
              <UserOutlined size={32} />
            )
          }
        />
        <Typography.Text ellipsis className={'text-white text-base font-bold'}>
          {auth?.user.firstname} {auth?.user.lastname}
        </Typography.Text>
      </div>
      <hr className={'mx-4 mt-8 mb-4 bg-white opacity-50'} />
      <Menu
        theme={'dark'}
        selectedKeys={[`${defaultSelectedKeys}`]}
        mode="inline"
        activeKey={`${defaultSelectedKeys}`}
        items={items}
      />
      <div style={{ flexGrow: 1 }} />
      <Button
        icon={<ExportOutlined />}
        iconPosition={'end'}
        onClick={handleLogout}
        type={'default'}
        className={'m-4 bg-transparent text-white border-white hover-white'}
      >
        {t('global:logout')}
      </Button>
    </Sider>
  );

  return (
    <>
      <Drawer
        placement={'left'}
        className={'flex md:hidden items-stretch flex-col !bg-primary'}
        bodyStyle={{ padding: 0 }}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
      >
        {slider(
          '!w-full h-full !max-w-full [& .ant-menu]:w-full [&>div]:h-full',
        )}
      </Drawer>
      <Layout style={{ height: '100vh' }}>
        <Header
          className={
            'flex items-center justify-between bg-white lg:h-[88px] md:px-8 px-4'
          }
        >
          <LogoIcon className={'lg:w-[146px] w-[85px]'} />
          <Typography.Title
            level={1}
            className={
              'hidden md:flex md:!text-2xl lg:!text-3xl leading-none !m-0'
            }
          >
            {t('global:hero')}
          </Typography.Title>
          <div className={'flex gap-2 items-center'}>
            <Button
              type={'primary'}
              icon={<PlusOutlined />}
              iconPosition={'end'}
              size={'large'}
            >
              {t('global:rdv')}
            </Button>
            <Button
              size={'large'}
              className={
                'border-none flex gap-0.5 bg-BleuHellio-1 hover:!text-primary hover:!bg-BleuHellio-1 px-2'
              }
            >
              <BellOutlined className={'!mb-0 text-xl'} size={24} />
              <span
                className={
                  '!mb-0 bg-primary border border-white text-white px-1 py-[1px] leading-none rounded-full font-normal text-[10px]'
                }
              >
                3
              </span>
            </Button>
            <Button
              onClick={() => setMenuOpen(true)}
              className={'flex md:hidden'}
              type={'text'}
              icon={<MenuOutlined />}
            />
          </div>
        </Header>
        <Layout className={'items-stretch bg-layout'}>
          {slider('hidden md:flex')}
          <Layout className={'bg-layout'}>
            <Content className={'overflow-auto py-4 md:pl-0 md:pr-7 px-4'}>
              {children}
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default React.memo(AppLayout);
